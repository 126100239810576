import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Title, Wrapper, Input, SaveButton} from 'whatcrm-core';

const Url = () => {
  const [field, meta, helpers] = useField('url');

  const isValid = !(meta.error && meta.touched);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Your link`}</Title.H3>

      <Wrapper
        gap={8}
        mobileWrap
      >
        <Input
          {...field}
          placeholder="https://"
          inputMode="url"
          width="max"
          autoCapitalize="off"
          autoFocus
          isValid={isValid}
          onChange={helpers.setValue}
        />

        <SaveButton>{t`Shorten`}</SaveButton>
      </Wrapper>
    </div>
  );
};

export default Url;
