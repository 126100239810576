import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {
  Header as CoreHeader,
  Wrapper,
  SingleSelect,
  useActions
} from 'whatcrm-core';
import {t} from 'ttag';
import {useAppContext} from '../../app-context';

const Header = () => {
  const {lang, theme, availableLangs, setTheme} = useAppContext();

  const {pathname} = useLocation();
  const {putInLocalStorage} = useActions();

  const handleLangChange = (value: number | string | undefined): void => {
    putInLocalStorage('lang', value as string);
    window.location.reload();
  };

  const themeOptions = [
    {
      value: 'light',
      label: t`Light`
    },
    {
      value: 'dark',
      label: t`Dark`
    },
    {
      value: 'system',
      label: t`System`
    }
  ];

  return (
    <CoreHeader>
      <Wrapper
        justifyContent="space-between"
        alignItems="center"
        noWrap
      >
        {pathname === '/' ? (
          <div className="logo" />
        ) : (
          <Link to="/">
            <div className="logo" />
          </Link>
        )}

        <Wrapper
          gap={16}
          noWrap
        >
          <SingleSelect
            options={themeOptions}
            value={theme}
            isTiny
            onChange={value => setTheme(value as NApp.TTheme)}
          />

          <SingleSelect
            options={availableLangs}
            value={lang}
            isTiny
            onChange={handleLangChange}
          />
        </Wrapper>
      </Wrapper>
    </CoreHeader>
  );
};

export default Header;
