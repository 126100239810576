import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './styles/main.sass';
import 'whatcrm-core/dist/main.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);
