export const removeFromLocalStorage = (name: string) => {
  try {
    localStorage.removeItem(name);
    return true;
  } catch {
    return false;
  }
};

export const normalizeUrl = (url: string) =>
  `https://${url.replaceAll('http://', '').replaceAll('https://', '')}`;

export const inflectNumberal = (number: number, labels: string[]) => {
  const remainder = number % 10;

  return number > 10 && number < 20
    ? labels[2]
    : remainder > 1 && remainder < 5
    ? labels[1]
    : remainder === 1
    ? labels[0]
    : labels[2];
};

export const getShortLinkUrl = (shortCode: string) =>
  `link.whatcrm.net/${shortCode}`;
