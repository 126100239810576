import React, {useContext} from 'react';

interface IAppContext {
  lang: NApp.TLang;
  theme: NApp.TTheme;
  availableLangs: {value: string; label: string}[];
  setTheme: React.Dispatch<React.SetStateAction<NApp.TTheme>>;
  pushNotification: (notification: NApp.INotification) => void;
}

const AppContext = React.createContext<IAppContext>({} as IAppContext);

export const useAppContext = () => useContext(AppContext);
export default AppContext;
