import React from 'react';
import moment from 'moment';
import {TableRow, TableCell} from 'whatcrm-core';

interface IVisitor {
  visitor: NApp.ICounters;
}

const Visitor = ({visitor}: IVisitor) => {
  const url = visitor.referer && new URL(visitor.referer);

  return (
    <TableRow>
      <TableCell>
        {moment(visitor.date.replace(' UTC', 'Z')).format('L, LT')}
      </TableCell>

      <TableCell>
        {url && `${url.host}${url.pathname !== '/' ? url.pathname : ''}`}
      </TableCell>

      <TableCell>{visitor.os}</TableCell>
      <TableCell>{visitor.browser}</TableCell>
      <TableCell>{visitor.ip}</TableCell>
    </TableRow>
  );
};

export default Visitor;
