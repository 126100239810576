import axios from 'axios';
import {t} from 'ttag';
import {useAppContext} from '../app-context';

interface IRequest {
  path: string;
  method?: 'get' | 'post' | 'put' | 'delete';
  data?: object;
}

export const useRequest = () => {
  const {pushNotification} = useAppContext();

  const throwError = () =>
    pushNotification({
      title: t`Error`,
      text: t`Something went wrong.`
    });

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {'X-Shorter-Token': process.env.REACT_APP_KEY}
  });

  const request = async ({path, method = 'get', data}: IRequest) => {
    const res = await instance(path, {
      method,
      data
    })
      .then(res => {
        interface ISuccess {
          success: 0 | 1 | undefined;
        }

        const {success}: ISuccess = res.data;

        if (success === 1 || success === undefined) {
          return res.data || {};
        }

        throwError();
        return {};
      })
      .catch(() => {
        throwError();
        return {};
      });

    return res;
  };

  const encodeUrlReq = (url: string) =>
    request({path: 'encode', method: 'post', data: {url}});

  const getShortLinkReq = (shortcode: string) =>
    request({path: `${shortcode}/info`});

  const updateShortLinkReq = (shortcode: string, expires: string) =>
    request({path: shortcode, method: 'put', data: {expires}});

  const deleteShortLinkReq = (shortcode: string) =>
    request({path: shortcode, method: 'delete'});

  return {
    encodeUrlReq,
    getShortLinkReq,
    updateShortLinkReq,
    deleteShortLinkReq
  };
};
