import React, {useEffect, useState} from 'react';
import {NotificationList, useLang, useTheme} from 'whatcrm-core';
import {LocaleData, addLocale, useLocale} from 'ttag';
import update from 'immutability-helper';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/uk';
import Router from './Router';
import AppContext from './app-context';
import de from './locale/de.po.json';
import es from './locale/es.po.json';
import pt from './locale/pt.po.json';
import ru from './locale/ru.po.json';
import uk from './locale/uk.po.json';

function App() {
  const [notificationList, setNotificationList] = useState<
    NApp.INotification[]
  >([]);

  const {availableLangs, lang} = useLang();
  const {theme, setTheme} = useTheme();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('./service-worker.js');
    }
  }, []);

  const pushNotification = (notification: NApp.INotification) =>
    setNotificationList(prevValue =>
      update(prevValue, {
        $push: [notification]
      })
    );

  if (lang !== 'en') {
    const locales: {[key: string]: object} = {de, es, pt, ru, uk};
    addLocale(lang, locales[lang] as LocaleData);
  }

  useLocale(lang);
  moment.locale(lang);

  return (
    <AppContext.Provider
      value={{lang, theme, availableLangs, setTheme, pushNotification}}
    >
      <div className="app">
        <Router />

        <NotificationList
          notificationList={notificationList}
          setNotificationList={setNotificationList}
        />
      </div>
    </AppContext.Provider>
  );
}

export default App;
