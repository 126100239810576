import React from 'react';
import update from 'immutability-helper';
import * as yup from 'yup';
import {Formik, Form, FormikHelpers} from 'formik';
import Url from './url/url';
import {useHomeContext} from '../home-context';
import {useRequest} from '../../../common/request';
import {normalizeUrl} from '../../../common/actions';

interface IValues {
  url: string;
}

const Creator = () => {
  const {setShortLinkList} = useHomeContext();
  const {encodeUrlReq} = useRequest();

  const initialValues = {url: ''};

  const validationSchema = yup.object().shape({
    url: yup.string().required()
  });

  const handleSubmit = async (
    values: IValues,
    formikHelpers: FormikHelpers<IValues>
  ) => {
    const res: NApp.IShortLink = await encodeUrlReq(normalizeUrl(values.url));

    if (Object.keys(res).length) {
      setShortLinkList(prevValue =>
        update(prevValue, {
          $unshift: [res]
        })
      );

      formikHelpers.resetForm();
    }

    return true;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      <Form>
        <Url />
      </Form>
    </Formik>
  );
};

export default Creator;
