import React, {useContext} from 'react';

interface IHomeContext {
  shortLinkList: NApp.IShortLink[] | undefined;
  setShortLinkList: React.Dispatch<
    React.SetStateAction<NApp.IShortLink[] | undefined>
  >;
}

const HomeContext = React.createContext<IHomeContext>({} as IHomeContext);

export const useHomeContext = () => useContext(HomeContext);
export default HomeContext;
