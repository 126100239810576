import React from 'react';
import {Title, Text} from 'whatcrm-core';

interface IHeadline {
  title: React.ReactNode;
  text: string;
  isTitleOneLine?: boolean;
}

const PageHeadline = ({title, text, isTitleOneLine = false}: IHeadline) => (
  <div>
    <Title.H1
      className={isTitleOneLine ? 'one-line' : ''}
      style={{marginBottom: 8}}
    >
      {title}
    </Title.H1>

    <Text isParagraph>{text}</Text>
  </div>
);

export default PageHeadline;
