import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import {Wrapper, EmptyBox, Preloader, useActions} from 'whatcrm-core';
import Creator from './creator/creator';
import ShortLinkList from './short-link-list/short-link-list';
import PageHeadline from '../../library/page-headline/page-headline';
import HomeContext from './home-context';
import {useRequest} from '../../common/request';
import {removeFromLocalStorage} from '../../common/actions';

const Home = () => {
  const [shortLinkList, setShortLinkList] = useState<NApp.IShortLink[]>();

  const {getFromLocalStorage, putInLocalStorage} = useActions();
  const {getShortLinkReq} = useRequest();

  const getShortLink = async (shortcode: string): Promise<NApp.IShortLink> => {
    const res = await getShortLinkReq(shortcode);
    return res;
  };

  const getShortLinkList = async () => {
    const shortCodeList = getFromLocalStorage('short-code-list');

    if (typeof shortCodeList === 'string') {
      const shortCodeListRes: string[] = JSON.parse(shortCodeList);
      const tempShortLinkList = [];

      for (const item of shortCodeListRes) {
        const shortLink = await getShortLink(item);

        if (Object.keys(shortLink).length) {
          tempShortLinkList.push(shortLink);
        }
      }

      setShortLinkList(tempShortLinkList);
      return;
    }

    setShortLinkList([]);
  };

  useEffect(() => {
    getShortLinkList();
  }, []);

  useEffect(() => {
    if (shortLinkList && shortLinkList.length) {
      putInLocalStorage(
        'short-code-list',
        JSON.stringify(shortLinkList.map(item => item.shortcode))
      );

      return;
    }

    removeFromLocalStorage('short-code-list');
  }, [shortLinkList]);

  return (
    <HomeContext.Provider value={{shortLinkList, setShortLinkList}}>
      <Wrapper
        gap={32}
        isColumn
      >
        <PageHeadline
          title="Whatcrm Link"
          text={t`A free service for creating short links.`}
        />

        <Creator />

        {shortLinkList ? (
          shortLinkList.length > 0 ? (
            <ShortLinkList shortLinkList={shortLinkList} />
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </HomeContext.Provider>
  );
};

export default Home;
