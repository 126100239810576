import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {Body, Page} from 'whatcrm-core';
import Home from './pages/home/home';
import Visitors from './pages/visitors/visitors';
import Header from './components/header/header';
import Footer from './components/footer/footer';

const Router = () => (
  <BrowserRouter>
    <Header />

    <Body>
      <Page>
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />

          <Route
            path=":crm/:code/visitors"
            element={<Visitors />}
          />

          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </Page>
    </Body>
    <Footer />
  </BrowserRouter>
);

export default Router;
