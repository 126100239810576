import React, {useState} from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';
import {Link} from 'react-router-dom';
import {
  TableRow,
  TableCell,
  Anchor,
  Button,
  Icons,
  Confirmation
} from 'whatcrm-core';
import {useAppContext} from '../../../../app-context';
import {useHomeContext} from '../../home-context';
import {useRequest} from '../../../../common/request';
import {getShortLinkUrl} from '../../../../common/actions';
import {formatDate} from './actions';

interface IShortLink {
  index: number;
  shortLink: NApp.IShortLink;
}

const ShortLink = ({index, shortLink}: IShortLink) => {
  const {pushNotification} = useAppContext();
  const {setShortLinkList} = useHomeContext();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const {updateShortLinkReq, deleteShortLinkReq} = useRequest();

  const link = getShortLinkUrl(shortLink.shortcode);
  const url = new URL(shortLink.url);

  const updateExpirationDate = async () => {
    const res = await updateShortLinkReq(shortLink.shortcode, '168h');

    if (Object.keys(res).length) {
      setShortLinkList(prevValue =>
        update(prevValue, {
          [index]: {$set: res}
        })
      );

      pushNotification({
        title: link,
        text: t`The expiration date is extended by 7 days.`
      });
    }
  };

  const deleteShortLink = async () => {
    const res = await deleteShortLinkReq(shortLink.shortcode);

    if (res.success) {
      setShortLinkList(prevValue =>
        update(prevValue, {
          $splice: [[index, 1]]
        })
      );
    }

    return true;
  };

  const handleConfirm = async (res: boolean) => {
    if (res) {
      return deleteShortLink();
    }

    setIsConfirmationOpen(false);
    return true;
  };

  return (
    <TableRow>
      <TableCell noWrap>
        <Anchor
          href={shortLink.url}
          target="_blank"
        >
          {`${url.host}${url.pathname !== '/' ? '/...' : ''}`}
        </Anchor>
      </TableCell>

      <TableCell noWrap>
        <Anchor
          href={`https://${link}`}
          target="_blank"
        >
          {link}
        </Anchor>
      </TableCell>

      <TableCell noWrap>{formatDate(shortLink.start)}</TableCell>
      <TableCell noWrap>{formatDate(shortLink.end)}</TableCell>
      <TableCell>{shortLink.counter}</TableCell>

      <TableCell isIcon>
        <Link to={`/${shortLink.shortcode}/visitors`}>
          <Button
            color="transparent"
            tip={t`View visitors`}
            isDisabled={shortLink.counter < 1}
          >
            <Icons.ExternalLink />
          </Button>
        </Link>
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Extend for 7 days`}
          onClick={updateExpirationDate}
        >
          <Icons.Time />
        </Button>
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Delete`}
          onClick={() => setIsConfirmationOpen(true)}
        >
          <Icons.Trash />
        </Button>

        {isConfirmationOpen && (
          <Confirmation
            title={t`Are you sure you want to delete this short link?`}
            onClick={handleConfirm}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default ShortLink;
