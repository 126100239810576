import React from 'react';
import {Footer as CoreFooter, Wrapper, Text, Anchor} from 'whatcrm-core';
import {useAppContext} from '../../app-context';

const Footer = () => {
  const {lang} = useAppContext();

  const year = new Date().getFullYear();
  const domain = lang === 'ru' ? 'whatcrm.ru' : 'wapp.im';

  return (
    <CoreFooter>
      <Wrapper
        justifyContent="space-between"
        alignItems="center"
      >
        <Wrapper gap={16}>
          <Text
            color="dark"
            size="s"
          >
            {`2020 — ${year}`}
          </Text>

          <Text size="s">
            <Anchor
              href={`https://${domain}`}
              target="_blank"
              color="dark"
            >
              {domain}
            </Anchor>
          </Text>
        </Wrapper>

        <Text
          size="s"
          color="dark"
        >
          {process.env.REACT_APP_VERSION}
        </Text>
      </Wrapper>
    </CoreFooter>
  );
};

export default Footer;
