import React from 'react';
import {t, ngettext, msgid} from 'ttag';
import {Text, Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import ShortLink from './short-link/short-link';
import {useAppContext} from '../../../app-context';
import {inflectNumberal} from '../../../common/actions';

interface IShortLinkList {
  shortLinkList: NApp.IShortLink[];
}

const ShortLinkList = ({shortLinkList}: IShortLinkList) => {
  const {lang} = useAppContext();

  const labels =
    lang === 'ru'
      ? ['короткая ссылка', 'короткие ссылки', 'коротких ссылок']
      : ['коротке посилання', 'коротких посилання', 'коротких посилань'];

  const isSlavic = lang === 'ru' || lang === 'uk';

  return (
    <div>
      <Text
        size="s"
        isParagraph
        style={{marginBottom: 8}}
      >
        {shortLinkList.length}
        {' '}

        {isSlavic
          ? inflectNumberal(shortLinkList.length, labels)
          : ngettext(msgid`short link`, `short links`, shortLinkList.length)}
      </Text>

      <Table>
        <TableHead>
          <TableCell>{t`Your link`}</TableCell>
          <TableCell>{t`Short link`}</TableCell>
          <TableCell>{t`Creation date`}</TableCell>
          <TableCell>{t`Expiration date`}</TableCell>
          <TableCell>{t`Visitors`}</TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
        </TableHead>

        <TableBody>
          {shortLinkList.map((item, i) => (
            <ShortLink
              key={item.shortcode}
              index={i}
              shortLink={item}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ShortLinkList;
