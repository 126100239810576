import React, {useState, useEffect} from 'react';
import {Navigate, Link, useParams} from 'react-router-dom';
import {t} from 'ttag';
import {
  Wrapper,
  Button,
  Icons,
  Anchor,
  Table,
  TableHead,
  TableBody,
  TableCell,
  EmptyBox,
  Preloader
} from 'whatcrm-core';
import Visitor from './visitor/visitor';
import PageHeadline from '../../library/page-headline/page-headline';
import {useRequest} from '../../common/request';
import {getShortLinkUrl} from '../../common/actions';

const Visitors = () => {
  const {crm, code} = useParams();
  const shortCode = `${crm}/${code}`;

  const [visitorList, setVisitorList] = useState<NApp.ICounters[] | null>(null);
  const [isNavigate, setIsNavigate] = useState(false);

  const {getShortLinkReq} = useRequest();

  const getVisitorList = async () => {
    const res: NApp.IShortLink = await getShortLinkReq(shortCode);

    if (Object.keys(res).length) {
      setVisitorList(res.counters || []);
      return;
    }

    setIsNavigate(true);
  };

  useEffect(() => {
    getVisitorList();
  }, []);

  const url = getShortLinkUrl(shortCode);

  return (
    <>
      {isNavigate ? (
        <Navigate to="/" />
      ) : (
        <Wrapper
          gap={32}
          isColumn
        >
          <Link to="/">
            <Button color="white">
              <Icons.AngleLeft />
              {t`Back`}
            </Button>
          </Link>

          <PageHeadline
            title={
              <Anchor
                href={url}
                target="_blank"
              >
                {url}
              </Anchor>
            }
            text={t`All visitors.`}
            isTitleOneLine
          />

          {visitorList ? (
            visitorList.length ? (
              <Table>
                <TableHead>
                  <TableCell>{t`Date`}</TableCell>
                  <TableCell>{t`Source`}</TableCell>
                  <TableCell>{t`Operating system`}</TableCell>
                  <TableCell>{t`Browser`}</TableCell>
                  <TableCell>{t`IP address`}</TableCell>
                </TableHead>

                <TableBody>
                  {visitorList.map((item, i) => (
                    <Visitor
                      key={i}
                      visitor={item}
                    />
                  ))}
                </TableBody>
              </Table>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Visitors;
